// Load popperjs
window.Popper = require('popper.js').default;
// Load jquery
window.$ = require('jquery');
// Load bootstrap
require('bootstrap');
// reference window.jQuery as window.$
window.jQuery = window.$;
// Load slick carousel
require('slick-carousel');
// Load a custom javascript file
require('./sliders');
require('./admin.js');
import Counter from "./counter";

require('./dog-age-calc');


//window._ = require('lodash');
// window.axios = require('axios');
// window.Vue = require('vue');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
//
// window.Vue.component('example-component', require('./components/ExampleComponent.vue'));
//
// const app = new window.Vue({
//   el: '#app',
// });

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Wordpress Json back-end. This library automatically handles sending the
 * Nonce token as a header based on the value of the "nonce" token cookie.
 */

// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
//
// const token = window.wp.nonce;
//
// if (token) {
//   window.axios.defaults.headers.common['X-WP-Nonce'] = token;
// } else {
//   console.error('nonce token not found');
// }


$(document).ready(() => {
    Counter.init();
});

//SHOW/HIDE REVIEWS FORM
$('#reply-title').on('click', function (e) {
  $('#commentform').slideToggle();
});

$(window).load(function () {
  heightOfNav();

  $(window).on('resize', function () {
    heightOfNav();
  });

  function heightOfNav() {
    const navHeight = $('header.banner').outerHeight(true);

    $('#primary_nav').css('top', navHeight);
  }


  $(document).on('click',function(){
    $('.navbar-collapse.collapse.show').collapse('hide');
  });

  $('.search-icon').on('click', function (e) {
    e.preventDefault();

    $('.search-form-container').slideToggle(function() {
      $(".search-field").focus();
    });
  });

  //STOP BOOTSTRAP ACCORDION BODY CLOSING ON CLICK
  $('#productAccordion .collapse').click(function(e){
    e.stopPropagation();

    $('.navbar-collapse.collapse.show').collapse('hide');
  });

  $('#productAccordion .collapse').on('show.bs.collapse', function(e) {
    var $card = $(this).closest('.card');
    var $open = $($(this).data('parent')).find('.collapse.show');

    var additionalOffset = 0;
    if($card.prevAll().filter($open.closest('.card')).length !== 0)
    {
      additionalOffset =  $open.height();
    }
    $('html,body').animate({
      scrollTop: $card.offset().top - (additionalOffset + 150)
    }, 500);
  });
});




