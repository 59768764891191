let sliders = {
    init: function () {
      $('#hero_banner').not('slick-initialized').slick({
        infinite: true,
        dots: true,
        arrows: true,
        prevArrow: '<div class="arrow-prev"></div>',
        nextArrow: '<div class="arrow-next"></div>',
        adaptiveHeight: true,
        autoplay: false,
      });

      $('.top-bar-statement').not('slick-initialized').slick({
        infinite: true,
        dots: false,
        arrows: false,
        autoplay: true,
      });

        $('.testimonials').not('slick-initialized').slick({
            fade: true,
            infinite: true,
            dots: true,
            appendDots: $('.testimonial-dots'),
            arrows: true,
            appendArrows: $('.testimonial-controls'),
            prevArrow: '<div class="arrow-prev"></div>',
            nextArrow: '<div class="arrow-next"></div>',
            adaptiveHeight: true,
        });

      $('.product-gallery-slick').not('slick-initialized').slick({
        lazyload: 'ondemand',
        infinite: true,
        dots: true,
        arrows: true,
        prevArrow: '<div class="arrow-prev"></div>',
        nextArrow: '<div class="arrow-next"></div>',
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.product-gallery-slick-thumbnails',
        autoplay: true,
        adaptiveHeight: false,
      });

      $('.product-gallery-slick-thumbnails').not('slick-initialized').slick({
        lazyload: 'ondemand',
        slidesToShow: 4,
        slidesToScroll: 1,
        asNavFor: '.product-gallery-slick',
        dots: false,
        arrows: true,
        prevArrow: '<div class="arrow-prev"></div>',
        nextArrow: '<div class="arrow-next"></div>',
        focusOnSelect: true,
        adaptiveHeight: false,
      });
    },
    breakdown: () => {
        $('#hero_banner.slick-initialized').slick("unslick");
        $('.testimonials.slick-initialized').slick("unslick");
      $('.product-gallery-slick').slick("unslick");
      $('.product-gallery-slick-thumbnails').slick("unslick");
      $('.top-bar-statement').slick("unslick");
    },
};


// import simpleParallax from 'simple-parallax-js';
//
$(document).ready(function () {
    let Sliders = Object.create(sliders);
    Sliders.init();
//
//
//     /* add parallax effect
//      * for config options see: https://github.com/geosigno/simpleParallax.js#settings
//      */
//     const parallax = document.querySelectorAll('#hero_banner.slick-initialized img');
//     new simpleParallax(parallax);
//
});
