$(document).ready(function () {

  $('#btnAgeCalc').bind('click', function () {
    calculateDogAge();
  });
});

function calculateDogAge() {
  var humanYears = $('#dogAge').val();
  var dogSize = $('#dogSize').val();
  var dogYears = 0;

  switch (dogSize) {
    case '1': //small
      if (humanYears > 2) {
        dogYears = ((humanYears - 2) * 4) + 24;
      }
      else {
        if (humanYears <= 1) {
          dogYears = (humanYears * 12/3) * 3.75;
        }
        else {
          dogYears = ((humanYears-1) * 12 / 3) * 2.25+15;
        }
      }
      break;
    case '2': //medium
      if (humanYears > 1.5) {
        dogYears = ((humanYears - 1) * 5) + 20;
      }
      else if (humanYears == 1.5) {
        dogYears = 20;
      }
      else {

        dogYears = (humanYears * 12 / 3) * 3.75;

      }
      break;
    case '3': //large
      if (humanYears >= 4) {
        dogYears = ((humanYears - 4) * 7) + 33;
      }
      else if (humanYears <= 1) {
        dogYears = humanYears * 12;
      }
      else if (humanYears == 1.5) {
        dogYears = 15.5;
      }

      else if (humanYears == 2) {
        dogYears = 19;
      }
      else if (humanYears == 3) {
        dogYears = 24;
      }
      else if (humanYears == 4) {
        dogYears = 33;
      }
      break;
    case '4'://giant
      if (humanYears >= 4) {
        dogYears = ((humanYears - 4) * 7) + 33;
      }
      else if (humanYears <= 1) {
        dogYears = humanYears * 12;
      }
      else if (humanYears == 1.5) {
        dogYears = 15.5;
      }

      else if (humanYears == 2) {
        dogYears = 19;
      }
      else if (humanYears == 3) {
        dogYears = 24;
      }
      else if (humanYears == 4) {
        dogYears = 33;
      }

      break;
    default:
      dogYears = 0;
      break;
  };
  var dogStage = '';
  if (dogYears < 18) {
    dogStage = 'a puppy';
  } else if (dogYears < 45) {
    dogStage = 'an adult';
  } else {
    dogStage = 'a senior';
  }

  $('#ageCalcResults').html('<h4>Your dog is equivalent to '+dogYears+' years old in human years. It is considered '+dogStage+'.</h4>').show();

}
/***End Dog Age Calculator***/
