/**
 *
 * Various utility methods, usage via import
 *
 * ( ie. import { rotate } from "./util.js" )
 */


/**
 * toggle document scroll On / Off
 *
 * @param state toggle state
 */
export function toggleScroll(state) {
    if(state) {
        $("html").css({
            "overflow-y": "",
        });
        $("body").css({
            "overflow-y": "",
        });
    } else {
        $("html").css({
            "overflow-y": "hidden",
        });
        /*        $("body").css({
                    "overflow-y": "scroll"
                });*/
    }
}

/**
 * Prevent default event propagation on given event object
 * @param ev
 */
export function stopDefault(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
}

/**
 * Standard math clamp function
 *
 * @param val
 * @param min
 * @param max
 * @returns {number}
 */
export function clamp(val, min, max) {
    return Math.min(Math.max(val, min), max);
}

/**
 * Apply a rotate animation on a given element
 *
 * @param selector Element selector
 * @param rotAngle Angle to rotate to (default 360 deg)
 * @param duration Speed / duration of animation (default 300ms)
 * @param relative Animate relative to current rotation
 */
export function rotate(selector, rotAngle = 360, duration = 300, relative = false) {
    const $elem = $(selector);
    let initial = 0;
    let rot = rotAngle;

    if(relative) {
        const css = $elem.css("transform");
        const values = css.split( '(' )[1].split(')' )[0].split(',');
        const angle = Math.round(Math.atan2(values[1],values[0]) * (180/Math.PI));
        const parsedAngle = (angle < 0 ? angle + 360 : angle);

        initial = parsedAngle;
        rot = initial + rotAngle;
    }

    $( {deg: initial} ).animate({deg: rot}, {
        duration: duration,
        step: function(now) {
            $elem.css({
                transform: "rotate(" + now + "deg)",
            });
        },
    });
}

/**
 * Check if browser is IE10 / IE11, checking feature detection
 */
export function isIE() {
     return /*@cc_on!@*/false || !!document.documentMode;
}

/**
 * Check if browser is IE10 / IE11, checking browser UserAgent
 * Note that browser detection via UA string is considered unreliable
 */
export function isIEUserAgent() {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    // eslint-disable-next-line no-useless-escape
    return (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./));
}

/**
 * Check whether selected element is a specified percentage above the screen's bottom edge (above the fold)
 *
 * @param $elem JQuery object representation of target element
 * @param decimalPercentage percentage above screen edge (in decimal)
 *
 *
 * */
export function elementInPosition($elem, decimalPercentage) {
    if( !$elem.is(":visible") ) {
        return false;
    }
    const elemPos = $elem.offset().top;
    const windowBottom = $(window).scrollTop() + ( $(window).height() * decimalPercentage );
    return elemPos <= windowBottom;
}

/**
 * iframe elements do not handle click events; provide a workaround method to respond on window blur event
 * which indicates iframe
 *
 * @param callback
 */
export function iframeOnClick(selector, callback) {
    const $window = $(window);

    $window.focus();
    $(document).click();
    $window.on("blur", () => {
        const activeElem = document.activeElement;
        if( activeElem.nodeName === "IFRAME" && document.querySelector(selector).isSameNode(activeElem)) {
            callback();
        }
    });
}


/**
 * Timer to calculate deltaTime since last call, because why not. timer value in milliseconds
 */
export class DeltaTimer {

    constructor() {
        this._timeNow = 0;
        this._timePast =  0;
        this.dt = 0;
        this._isRunning = true;
    }

    start() {
        this._isRunning = true;

        const update = () => {
            this._timeNow = Date.now();
            this.dt = (this._timeNow - this._timePast);

            if(this._isRunning) {
                setTimeout(() =>{
                    update();
                }, 0);
            }
        };

        setTimeout(() =>{
            update();
        }, 0);
    }

    stop() {
        this._isRunning = false;
    }
}

/**
 * Wrap any function into an asynchronous, then-able format that can be executed in sequence
 *
 * @param foo - the function to be called
 * @param delay - intentionally delay the next function call by X milliseconds
 */
export function chainCallback(foo, delay = 0) {
    const $def = new $.Deferred();

    setTimeout(() => {
        $def.resolve(foo());
    }, delay);

    return $def;
}

/**
 * Javascript method of checking if user device is mobile
 * uses feature detection, plus UA string checks for Apple devices
 *
 * @returns {boolean}
 */
export function isMobileDevice() {
    const noHover = window.matchMedia("screen and (hover: none)").matches;
    const noMouse = window.matchMedia("screen and (pointer: coarse)").matches;

    let result = (noHover && noMouse);

    if (result === false) { //double check for Safari
        result = safariUACheck();
    }
    return result;
}
function safariUACheck() {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iP(ad|hone)/i);
    const webkit = !!ua.match(/WebKit/i);
    return iOS && webkit && !ua.match(/CriOS/i);
}



/**
 * Simple implementation of the Publisher /Subscriber pattern. Useful for signalling events between separate modules triggering
 * callbacks, similar to addEventListener
 *
 */
export class PubSub {
    /**
     * Publish a given event
     *
     * @param event : String event name
     * @param data : Any data to be passed to callback
     */
    publish(event, data = null) {
        const eventSubs = this.subscribers[event];

        if (!eventSubs) {
            return
        }

        eventSubs.forEach(object => {
            Object.values(object)[0](data);
        });
    }

    /**
     * Subscribe to an event trigger
     *
     * @param object : Any reference to subscribing object
     * @param event : String event name
     * @param callback : Function callback function
     */
    subscribe(object, event, callback) {
        let subs = this.subscribers[event];

        if(!subs) {
            subs = this.subscribers[event] = [];
        }

        subs.push({object: callback});
    }

    /**
     * Unsubscribe from an event trigger, removing the specific callback function
     *
     * @param object : Any reference to unsubscribing object
     * @param event : String event name
     */
    unsubscribe(object, event) {
        const eventSubs = this.subscribers[event];

        if (!eventSubs) {
            return
        }


        eventSubs.forEach(object, i => {
            if(Object.keys(object)[0] === object) {
                eventSubs.splice(i, 1);
            }
        });
    }
}
PubSub.prototype.subscribers = {};


